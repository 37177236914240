<template>
  <div id="app">
    <notifications group="notifications_home" position="top right" width="100%">
      <template slot="body" slot-scope="{ item, close }">
        <div class="notification-home" :class="[item.type]" @click="close">
          <b-button @click="close">
            <vue-fontawesome icon="close" size="1" color="#FFF"></vue-fontawesome>
          </b-button>
          <div class="body-notification">
            <div class="body_icon"></div>
            <div class="body_text">
              {{item.text}}
            </div>
          </div>
        </div>
      </template>
    </notifications>
    <router-view/>
    <ContactUs/>
  </div>
</template>

<script>
import ContactUs from '@/views/Home/ContactUs.vue';

export default {
  components: { ContactUs },
}
</script>

<style lang="scss" src="./assets/stylesheets/application.scss"></style>
