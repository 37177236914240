import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    name: 'home',
    path: '/',
    component:() => import('../views/Home/Index.vue')
  },
  {
    name: 'about_us',
    path: '/about-us',
    component: () => import('../views/AboutUs.vue')
  },
  {
    name: 'register',
    path: '/register',
    component: () => import('../views/Auth/SignUp.vue')
  },
  {
    name: 'register.success',
    path: '/register/success',
    component: function () {
      return import('../views/Auth/SignUpSuccess.vue')
    }
  },
  {
    name: 'verify.account',
    path: '/verify/account/:token',
    component: function() {
      return import('../views/Auth/AccountVerification.vue')
    }
  },
  {
    name: 'login',
    path: '/login',
    component: () => import('../views/Auth/Login.vue')
  },
  {
    name: 'logout',
    path: '/logout',
    component() {
      return import('../views/Auth/Logout.vue');
    },
  },
  {
    name: 'password.reset',
    path: '/password/reset',
    component() {
      return import('../views/Auth/Password/ForgotPassword.vue');
    },
  },
  {
    name: 'password.reset.token',
    path: '/password/reset/:token',
    component() {
      return import('../views/Auth/Password/ResetPassword.vue');
    },
  },
  {
    name: 'account_info',
    path: '/account',
    component() {
      return import('../views/Account/AccountInformation.vue');
    },
  },
  {
    name: 'page.419',
    path: '/419',
    component() {
      return import('../views/Errors/419.vue');
    },
  },
  {
    name: 'page.404',
    path: '*',
    component() {
      return import('../views/Errors/404.vue');
    },
  },
  {
    name: 'dashboard',
    path: '/dashboard',
    component() {
      return import('../views/Dashboard/Index.vue');
    },
    children: [
      {
        name: 'notifications',
        path: '/dashboard/notifications',
        component:() => import('../views/Dashboard/Notifications/Index.vue')
      },
      {
        name: 'monitors.index',
        path: '/dashboard/monitors',
        component:() => import('../views/Dashboard/Monitors/Index.vue')
      },
      {
        name: 'monitors.create',
        path: '/dashboard/monitors/add',
        component:() => import('../views/Dashboard/Monitors/Add.vue')
      },
      {
        name: 'monitors.add',
        path: '/dashboard/monitors/create',
        component:() => import('../views/Dashboard/Monitors/Create.vue')
      },
      {
        name: 'services.create',
        path: '/dashboard/monitors/:id/services/add',
        component:() => import('../views/Dashboard/Monitors/Services/Add.vue')
      },
      {
        name: 'alerts',
        path: '/dashboard/alerts',
        component:() => import('../views/Dashboard/Alerts/Index.vue')
      },
      {
        name: 'alerts.add',
        path: '/dashboard/alerts/add',
        component:() => import('../views/Dashboard/Alerts/Add.vue')
      },
      {
        name: 'operators',
        path: '/dashboard/operators',
        component:() => import('../views/Dashboard/Operators/Index.vue')
      },
      {
        name: 'operators.add',
        path: '/dashboard/operators/add',
        component:() => import('../views/Dashboard/Operators/Add.vue')
      },
      {
        name: 'operator-groups',
        path: '/dashboard/operator-groups',
        component:() => import('../views/Dashboard/Operators/Groups/Index.vue')
      },
      {
        name: 'operator-groups.add',
        path: '/dashboard/operator-groups/add',
        component:() => import('../views/Dashboard/Operators/Groups/Add.vue')
      },
    ]
  },
  {
    path: '/legal',
    redirect: '/legal/privacy-policy',
    component() {
      return import('../views/LegalPages/Index.vue');
    },
    children: [
      {
        name: 'privacy-policy',
        path: '/legal/privacy-policy',
        component() {
          return import('../views/LegalPages/PrivacyPolicy.vue');
        },
      },
      {
        name: 'terms-service',
        path: '/legal/terms-service',
        component() {
          return import('../views/LegalPages/TermsOfService.vue');
        },
      },
      {
        name: 'end-user-agreement',
        path: '/legal/end-user-agreement',
        component() {
          return import('../views/LegalPages/EndUserAgreement.vue');
        },
      },
    ],
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router;
