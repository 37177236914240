import Vue from 'vue';
import VueResource from 'vue-resource';
import Router from '../../router/index';

Vue.use(VueResource);

// Local
//Vue.http.options.root = 'http://127.0.0.1:8000/api/';
// QA
Vue.http.options.root = 'http://qaapi.monitor.com/api/';
Vue.http.headers.common['Access-Control-Allow-Origin'] = true;
Vue.http.headers.common['Access-Control-Allow-Origin'] = '*';
Vue.http.headers.common['Accept-Language'] = 'es';
Vue.http.headers.common['DYNAMIC-LANGUAGE'] = 'es';
Vue.http.headers.common['Content-Type'] = 'application/json';
Vue.http.headers.common.Accept = 'application/json';
Vue.http.headers.common['Access-Control-Allow-Methods'] = 'GET, POST, PATCH, PUT, DELETE, OPTIONS';
Vue.http.headers.common['Access-Control-Allow-Headers'] = 'Origin, Content-Type, Accept-Language';
Vue.http.headers['Access-Control-Max-Age'] = '1728000';

// Vue.http.interceptors.push((request, next) => {
//   next((response) => {
//     if (response.status === 404 || response.status === 401) {
//       Router.app.$session.destroy();
//       if (Router.app.$route.name !== 'login') {
//         Router.app.$router.push({ name: 'login' });
//       }
//     }
//   });
// });
