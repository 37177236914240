<template>
  <b-modal id="contact-us" hide-footer centered>
    <b-container>
      <b-row>
        <b-col>
          <form @submit.prevent="sendForm" class="pb-4">
            <h3 class="text-center mb-4 fw-bold">Contact Us</h3>

            <!-- First name -->
            <b-form-group class="mb-4">
              <b-form-input type="text" placeholder="First name" trim
                :class="{'is-invalid': $v.form.first_name.$error}" v-model.trim="$v.form.first_name.$model"></b-form-input>

              <span role="alert" class="invalid-feedback" v-if="$v.form.first_name.$error">
                <span v-if="!$v.form.first_name.required">The First name is required</span>
                <span v-if="!$v.form.first_name.minLength">
                  The First name must have at least {{$v.form.first_name.$params.minLength.min}} characters
                </span>
                <span v-if="!$v.form.first_name.maxLength">
                  The First name must be less than {{$v.form.first_name.$params.maxLength.max}} characters
                </span>
              </span>
            </b-form-group>

            <!-- Last name -->
            <b-form-group class="mb-4">
              <b-form-input type="text" placeholder="Last name" trim
                :class="{'is-invalid': $v.form.last_name.$error}" v-model.trim="$v.form.last_name.$model"></b-form-input>

              <span role="alert" class="invalid-feedback" v-if="$v.form.last_name.$error">
                <span v-if="!$v.form.last_name.required">The Last name is required</span>
                <span v-if="!$v.form.last_name.minLength">
                  The Last name must have at least {{$v.form.last_name.$params.minLength.min}} characters
                </span>
                <span v-if="!$v.form.last_name.maxLength">
                  The Last name must be less than {{$v.form.last_name.$params.maxLength.max}} characters
                </span>
              </span>
            </b-form-group>

            <!-- Email -->
            <b-form-group class="mb-4">
              <b-form-input type="email" placeholder="Email" trim
                :class="{'is-invalid': $v.form.email.$error}" v-model.trim="$v.form.email.$model"></b-form-input>
              <span role="alert" class="invalid-feedback">
                <span v-if="!$v.form.email.required">The Email is required</span>
                <span v-if="!$v.form.email.email">Invalid Email format</span>
                <span v-if="!$v.form.email.maxLength">
                  The Email must be less than {{$v.form.email.$params.maxLength.max}} letters
                </span>
              </span>
            </b-form-group>

            <!-- Phone number -->
            <b-form-input type="text" placeholder="Phone number" v-model.trim="form.phone" class="mb-4"></b-form-input>

            <!-- Country -->
            <b-form-group class="mb-4">
              <b-form-select v-model="$v.form.country.$model" :options="country_options" class="form-control"
                :class="{'is-invalid': $v.form.country.$error}"></b-form-select>
              <span role="alert" class="invalid-feedback" v-if="$v.form.country.$error">
                <span v-if="!$v.form.country.required">
                  The Country is required
                </span>
              </span>
            </b-form-group>

            <!-- Subject -->
            <b-form-group class="mb-4">
              <b-form-select v-model="$v.form.subject.$model" :options="subject_options" class="form-control"
                :class="{'is-invalid': $v.form.subject.$error}"></b-form-select>
              <span role="alert" class="invalid-feedback" v-if="$v.form.subject.$error">
                <span v-if="!$v.form.subject.required">
                  The Subject is required
                </span>
              </span>
            </b-form-group>

            <!-- Message -->
            <b-form-group class="mb-4">
              <b-form-textarea placeholder="Message" :class="{'is-invalid': $v.form.message.$error}"
                v-model.trim="$v.form.message.$model" rows="4"></b-form-textarea>

                <span role="alert" class="invalid-feedback" v-if="$v.form.message.$error">
                  <span v-if="!$v.form.message.required">
                    The Message is required
                  </span>
                  <span v-if="!$v.form.message.minLength">
                    The Message must have at least {{$v.form.message.$params.minLength.min}} letters
                  </span>
                  <span v-if="!$v.form.message.maxLength">
                    The Message must be less than {{$v.form.message.$params.maxLength.max}} letters
                  </span>
                </span>
            </b-form-group>

            <!-- Recaptcha -->
            <vue-recaptcha ref="recaptcha" sitekey="6LfZ-wYfAAAAAP7rkAPUU3RKiJixfyVmN4Ytz0TC" 
              size="invisible" @verify="validateRecaptcha" @expired="validateRecaptcha">
            </vue-recaptcha>

            <b-button type="submit" variant="primary" class="w-100">Submit</b-button>
          </form>
        </b-col>
      </b-row>
    </b-container>
    <b-overlay :show="load" rounded="sm" no-wrap></b-overlay>
  </b-modal>
</template>

<script>
import {required, email, minLength, maxLength} from 'vuelidate/lib/validators';
import VueRecaptcha from 'vue-recaptcha';

export default {
  components: { VueRecaptcha },
  data() {
    return {
      form: {
        first_name: String(),
        last_name: String(),
        email: String(),
        phone: String(),
        country: null,
        subject: null,
        message: String(),
        recaptcha: null,
      },
      country_options: [
        { value: null, text: 'Country' },
        { value: 'Country #1', text: 'Country #1' }
      ],
      subject_options: [
        { value: null, text: 'Subject' },
        { value: 'Subject #1', text: 'Subject #1' }
      ],
      load: false,
    }
  },
  validations: {
    form: {
      first_name: { required, minLength: minLength(2), maxLength: maxLength(60) },
      last_name: { required, minLength: minLength(2), maxLength: maxLength(60) },
      email: { required, email, maxLength: maxLength(60) },
      country: { required },
      subject: { required },
      message: { required, minLength: minLength(2), maxLength: maxLength(255) },
    },
  },
  methods: {
    sendForm() {
      this.$v.form.$reset();
      this.$v.form.$touch();
      if (!this.$v.form.$invalid) {
        this.load = true;
        this.generateRecaptcha();
      }
    },
    validateRecaptcha(response){
      this.$refs.recaptcha.reset();
      this.form.recaptcha = response;

      this.$http.post('contactUs', this.form).then((response) => {
          if (response.status === 200) {
            this.$notify({ group: 'notifications_home', text: response.body.message, type: 'confirmation' });
            this.form = {
              first_name: String(),
              last_name: String(),
              email: String(),
              phone: String(),
              country: null,
              subject: null,
              message: String(),
              recaptcha: null,
            };
            this.$v.form.$reset(response.body);
            this.$refs.recaptcha.reset();
          }
          this.load = false;
        }, (error) => {
          if (error.status === 400) {
            this.$notify({ group: 'notifications_home', text: error.body.message, type: 'warning' });
          } else if (error.status === 500) {
            this.$notify({ group: 'notifications_home', text: error.body.message, type: 'warning' });
          }
          this.load = false;
        });
    },
    generateRecaptcha(){
      this.$refs.recaptcha.execute();
    },
  }
};
</script>
