import Vue from 'vue';
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue';
import Vuelidate from 'vuelidate';
import VueSession from 'vue-session';
import Notifications from 'vue-notification';
import App from './App.vue';
import router from './router';
import './services/resource/index';
import './store/mixins';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import 'font-awesome/css/font-awesome.min.css';

Vue.use(BootstrapVue);
Vue.use(IconsPlugin);
Vue.use(Vuelidate);
Vue.use(VueSession, { persist: true });
Vue.use(Notifications);

Vue.config.productionTip = false;

Vue.component('VueFontawesome', require('vue-fontawesome-icon/VueFontawesome.vue').default);

new Vue({
  router,
  render: h => h(App)
}).$mount('#app');
