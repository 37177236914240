import Vue from 'vue';

Vue.mixin({
  methods: {
    checkLevelPassword(value){
      var levelPassword = {
        title: null,
        description: null,
        level: 'level_1',
        success: false
      };

      if (value != '') {
        if (!new RegExp("^(?=.{8,}).*$", "g").test(value)) {
          levelPassword.description = 'Your password must be at least 8 characters long.';
        }else if(!new RegExp("^(?=.*[a-z])(?=.*[A-Z]).*$", "g").test(value)){
          levelPassword.description = 'Your password must have a combination of uppercase and lowercase letters.';
        }else if(!new RegExp("^(?=.*\\d).*$", "g").test(value)){
            levelPassword.description = 'Your password must have at least one digit.';
        }else if(!new RegExp("^(?=.*\\W).*$", "g").test(value)) {
            levelPassword.description = 'Your password must be at least one special character.';
        }else{
            levelPassword.description = 'Your password is strong enough.';
        }

        if(new RegExp("^(?=.{8,})((?=.*[a-z])(?=.*[A-Z])(?=.*\\d)|(?=.*[a-z])(?=.*[A-Z])(?=.*\\W)|((?=.*[a-z])|(?=.*[A-Z]))(?=.*\\d)(?=.*\\W)).*$", "g").test(value)){
            levelPassword.success = true;
        }else{
            levelPassword.success = false;
        }

        if(new RegExp("^(?=.{8,})(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*\\W).*$", "g").test(value)){
            levelPassword.title = 'Strong';
            levelPassword.level = 'level_4';
            levelPassword.success = true;

        }else if(new RegExp("^(?=.{8,})((?=.*[a-z])(?=.*[A-Z])(?=.*\\d)|(?=.*[a-z])(?=.*[A-Z])(?=.*\\W)|((?=.*[a-z])|(?=.*[A-Z]))(?=.*\\d)(?=.*\\W)).*$", "g").test(value)){
            levelPassword.title = 'Medium';
            levelPassword.level = 'level_3';
            levelPassword.success = true;

        }else{
            levelPassword.title = 'Weak';
            levelPassword.level = 'level_3';

            if (new RegExp("^(?=.{8,}).*$", "g").test(value)) {
                levelPassword.level = 'level_2';
                levelPassword.success = false;
            }else{
                levelPassword.level = 'level_1';
                levelPassword.success = false;
            }
        }
      }
      return levelPassword;
    },
  },
});
